import React, { useEffect, useState } from 'react'
import { Routes, Route, useParams  } from "react-router-dom"
import { Inicio } from "../Inicio"
import { Home } from "../Auth/Home"
import { Lines } from "../Auth/Lines"
import { Movements } from "../Auth/Movements"
import { ConsultUF } from "../Auth/ConsultUF"
import { ActivateUF } from "../Auth/ActivateUF"
import { Recharge } from "../Auth/Recharge"
import { SuspendReactivateTraffic } from "../Auth/SuspendReactivateTraffic"
import { Consumptions } from "../Auth/Consumptions"
// import { PrivateRoute } from "./PrivateRoute"

export const AppRouter = () => {
    const [logged, setLogged] = useState(false)
    const [user_name, setUsername] = useState('')
    const [user_lastname, setUserLastname] = useState('')
    const [user_email, setUserEmail] = useState('')
    const [user_company, setUserCompany] = useState('')

    // VARIABLES PARA LA CARGA DE NAVBAR SEGUN EL PORTAL AL QUE ACCEDAN
    const [typeNavBar, setTypeNavBar] = useState('principal')
    const dealersRoutes = ['/login', '/home'];
    const clientsRoutes = ['/login-clients', '/home-clients'];
    const simpleRoutes = ['/'];

    useEffect(() => {
        const verifyLogged = () => {
            console.log("logged cambiado a ["+logged+"]")
            console.log("USER_COMPANY cambiado a ["+user_company+"]")
        }
    
        verifyLogged();
      }, [logged]);


    return (
        <>
            <Routes>
                <Route path="/" >
                    <Route index element={
                        <Inicio logged={logged} setLogged={setLogged} user_name={user_name} setUsername={setUsername} user_lastname={user_lastname} setUserLastname={setUserLastname} user_company={user_company} setUserCompany={setUserCompany} user_email={user_email} setUserEmail={setUserEmail} />} 
                    />

                    <Route path="/home" element={<Home logged={logged} setLogged={setLogged} user_name={user_name} setUsername={setUsername} user_lastname={user_lastname} setUserLastname={setUserLastname} user_company={user_company} setUserCompany={setUserCompany} user_email={user_email} setUserEmail={setUserEmail} />} />
                    <Route path="/lines" element={<Lines logged={logged} setLogged={setLogged} user_name={user_name} setUsername={setUsername} user_lastname={user_lastname} setUserLastname={setUserLastname} user_company={user_company} setUserCompany={setUserCompany} user_email={user_email} setUserEmail={setUserEmail} />} /> 
                    <Route path="/movements" element={<Movements logged={logged} setLogged={setLogged} user_name={user_name} setUsername={setUsername} user_lastname={user_lastname} setUserLastname={setUserLastname} user_company={user_company} setUserCompany={setUserCompany} user_email={user_email} setUserEmail={setUserEmail} />} /> 
                    <Route path="/consult-uf/:param1?" element={<ConsultUF logged={logged} setLogged={setLogged} user_name={user_name} setUsername={setUsername} user_lastname={user_lastname} setUserLastname={setUserLastname} user_company={user_company} setUserCompany={setUserCompany} user_email={user_email} setUserEmail={setUserEmail} />} /> 
                    <Route path="/activate-uf/:param1?" element={<ActivateUF logged={logged} setLogged={setLogged} user_name={user_name} setUsername={setUsername} user_lastname={user_lastname} setUserLastname={setUserLastname} user_company={user_company} setUserCompany={setUserCompany} user_email={user_email} setUserEmail={setUserEmail} />} />
                    <Route path="/recharge-uf/:param1?" element={<Recharge logged={logged} setLogged={setLogged} user_name={user_name} setUsername={setUsername} user_lastname={user_lastname} setUserLastname={setUserLastname} user_company={user_company} setUserCompany={setUserCompany} user_email={user_email} setUserEmail={setUserEmail} />} />
                    <Route path="/barring-unbarring/:param1?" element={<SuspendReactivateTraffic logged={logged} setLogged={setLogged} user_name={user_name} setUsername={setUsername} user_lastname={user_lastname} setUserLastname={setUserLastname} user_company={user_company} setUserCompany={setUserCompany} user_email={user_email} setUserEmail={setUserEmail} />} />
                    <Route path="/consumptions/:param1?" element={<Consumptions logged={logged} setLogged={setLogged} user_name={user_name} setUsername={setUsername} user_lastname={user_lastname} setUserLastname={setUserLastname} user_company={user_company} setUserCompany={setUserCompany} user_email={user_email} setUserEmail={setUserEmail} />} />
                    </Route>
            </Routes>
        </>
    )
}