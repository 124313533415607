import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { SideBar } from "../components/SideBar"
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "../styles/generic.css"
import "../styles/buttons.css"
import "../styles/inputs.css"
import "../styles/cards.css"
import { getTokenAltan, consultUFAPI } from '../helpers';
import 'chart.js/auto';
// import Loader from "../components/Loader"

export const ConsultUF = ({ logged, setLogged, user_name, setUsername, user_lastname, setUserLastname, user_company, setUserCompany, user_email, setUserEmail }) => {
  const { param1 } = useParams();
  const [accessToken, setAccessToken] = useState("empty");
  const [msisdn, setMsisdn] = useState("empty");
  const [ofertas, setOfertas] = useState([]);
  const [loader, setLoader] = useState(false);
  const [textLoader, setTextLoader] = useState("Cargando, espere...");
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const verifyLogged = () => {
      if(!logged){
        navigate('/');
      }
    }

    verifyLogged();
  }, [logged]);

  useEffect(() => {
    const fetchToken = async () => {
      setLoader(true);
      try {
        // Esperar a que la promesa se resuelva
        let tokenData = await getTokenAltan();
        
        // Verificar que el tokenData no tenga errores y actualizar el estado
        if (!tokenData.error) {
          setAccessToken(tokenData.accessToken);
          console.log(tokenData, "TOKEN DATA");
          console.log(tokenData.accessToken, "TOKEN ACCESS TOKEN");
          setIsDisabled(false);
        } else {
          console.error("Error:", tokenData.error_desc);
          Swal.fire({
            title: 'Uhhh :(',
            text: 'Error al obtener Token: '+tokenData.error_desc,
            icon: 'error'
          });
        }
        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.error("Error fetching token:", error);
        Swal.fire({
          title: 'Uhhh :(',
          text: 'Error al obtener Token: '+error,
          icon: 'error'
        });
      }
    };

    const showParam = () => {
      console.log(param1, " PARAMETRO");
      setMsisdn(param1);
    }

    fetchToken();
    showParam();
  }, []);

  const doConsult = async (data) => {
    setLoader(true);
    setIsDisabled(true);
    data.accessToken = accessToken;
    console.log(data, " DATA TO CONSULT UF")
    let response = await consultUFAPI(data);
    
    if (!response.error) {
      setOfertas(response.responseSubscriber.freeUnits);
    } else {
      console.error("Error:", response.error_desc);
      Swal.fire({
        title: 'Uhhh :(',
        text: 'Error: '+response.error_desc+' - '+response.description,
        icon: 'error'
      });
    }
    setLoader(false);
    setIsDisabled(false);
  }

    return(
    <>
    <div className="container">
        <SideBar logged={logged} setLogged={setLogged} user_name={user_name} setUsername={setUsername} user_lastname={user_lastname} setUserLastname={setUserLastname} user_company={user_company} setUserCompany={setUserCompany} user_email={user_email} setUserEmail={setUserEmail} />
        <div className="content">
            <div className="col-10 d-block">
              <div className="col-10 d-flex justify-content-center">
                <div className="form-container col-6 ">
                  <h2>Consulta de UF</h2>
                  <form>
                    <div className="form-group">
                        <label htmlFor="msisdn">MSISDN</label>
                        <input className="form-control" type="text" id="msisdn" name="msisdn" onChange={(e) => setMsisdn(e.target.value)} placeholder="5500000000" value={msisdn} required />
                    </div>
                    {loader && (
                      <div className="col-12">
                        <div className="loader-container">
                          <div className="loader"></div>
                          <div className='loader-text'>{textLoader}...</div>
                        </div>
                      </div>
                    )}
                    <div className="form-group">
                      <button type="button" disabled={isDisabled} className="btn mb-2 btn-success" onClick={() => doConsult({msisdn})}>Consultar</button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center mt-3">
                <div className="row col-12">
                  {ofertas.map((unit) => {
                    const porcentaje = (unit.freeUnit.unusedAmt / unit.freeUnit.totalAmt) * 100;
                    return (
                    <div className="col-3 mb-4 mx-1">
                      <div className="card shadow">
                        <div className="card-body">
                          <div className="row align-items-center">
                            <div className="col">
                              <span className="h2 mb-0">{porcentaje.toFixed(2)}% disponible</span>
                              <p className="small text-muted mb-0">{unit.name}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )})}
                    
                </div>
              </div>
            </div>
        </div>
    </div>
    </>
    )}