import { BrowserRouter, Routes, Route, Link } from "react-router-dom"
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppRouter } from "./components/AppRouter"
function App() {

  return (
    <AppRouter/>
  );

}

export default App;
