import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { SideBar } from "../components/SideBar"
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "../styles/generic.css"
import "../styles/buttons.css"
import "../styles/inputs.css"
import "../styles/cards.css"
import { getTokenAltan, consultUFAPI } from '../helpers';
import DataTable from 'react-data-table-component';
// import Loader from "../components/Loader"

export const Consumptions = ({ logged, setLogged, user_name, setUsername, user_lastname, setUserLastname, user_company, setUserCompany, user_email, setUserEmail }) => {
  const { param1 } = useParams();
  const [accessToken, setAccessToken] = useState("empty");
  const [msisdn, setMsisdn] = useState("empty");
  const [ofertas, setOfertas] = useState([]);
  const [loader, setLoader] = useState(false);
  const [textLoader, setTextLoader] = useState("Cargando, espere...");
  const [isDisabled, setIsDisabled] = useState(true);

  const [dateNow, setDateNow] = useState('');
  const [dateNow2, setDateNow2] = useState('');
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    const verifyLogged = () => {
      if(!logged){
        navigate('/');
      }
    }

    verifyLogged();
  }, [logged]);

  useEffect(() => {

    const showParam = () => {
      console.log(param1, " PARAMETRO");
      setMsisdn(param1);
    }

    const fechaHoraActual = obtenerFechaHoraActual();
    setDateNow(fechaHoraActual);
    setDateNow2(fechaHoraActual);

    showParam();
  }, []);

  const obtenerFechaHoraActual = () => {
    const fechaActual = new Date();
    
    const opciones = {
      timeZone: 'America/Mexico_City',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };

    // Formatear la fecha y hora
    return new Intl.DateTimeFormat('es-MX', opciones).format(fechaActual);
  };

  const handleFechaCambio = (e) => {
    const nuevaFecha = e.target.value;
    setDateNow(nuevaFecha);
  };

  const handleFechaCambio2 = (e) => {
    const nuevaFecha = e.target.value;
    setDateNow2(nuevaFecha);
  };

  function formatDate(fecha) {
    const dateUtc = new Date(fecha);
    
    // Obtener los componentes individuales de la fecha
    const day = String(dateUtc.getDate()).padStart(2, '0'); // Día
    const month = String(dateUtc.getMonth() + 1).padStart(2, '0'); // Mes (se suma 1 porque getMonth devuelve 0-11)
    const year = String(dateUtc.getFullYear()).slice(-2); // Últimos dos dígitos del año
    const hours = String(dateUtc.getHours()).padStart(2, '0'); // Hora
    const minutes = String(dateUtc.getMinutes()).padStart(2, '0'); // Minutos
    const seconds = String(dateUtc.getSeconds()).padStart(2, '0'); // Segundos
  
    // Formatear la fecha en el formato deseado: DD-MM-YY HH:mm:ss
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    
    return formattedDate;
  }


  const columns = [
    {
      name: 'id',
      selector: row => row.id,
      sortable: true,
    },
    {
        name: 'Charging Time',
        selector: row => formatDate(row.ChargingTime),
        sortable: true,
      },
    {
      name: 'Amount',
      selector: row => row.CHG_AMOUNT,
      sortable: true,
    },
    {
        name: 'Tipo',
        selector: row => row.tipo,
        sortable: true,
      },
    {
      name: 'IMEI',
      selector: row => row.IMEI,
      sortable: true,
    },
    {
      name: 'Número Destino',
      selector: row => row.RECIPIENT_NUMBER,
      sortable: true,
    }
  ];

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    // const filteredItems = data.filter(
    // item => item.id && item.id.includes(filterText)
    // );

    const filteredItems = data.filter(item => {
        return Object.values(item).some(value =>
          value?.toString().toLowerCase().includes(filterText.toLowerCase())
        );
      });

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <input className="form-control"
                        type="text"
                        placeholder="Search"
                        aria-label="Search Input"
                        value={filterText}
                        onChange={e => setFilterText(e.target.value)}
                        style={{ marginRight: '10px', padding: '5px' }}
                    />
                    <button onClick={handleClear}>Clear</button>
                </div>
            </>
        );
    }, [filterText, resetPaginationToggle]);

    const getConsumptios = async (parametros) => {
    try {
        console.log("COMENZANDO CONSUMO DE API...");
        const response = await fetch('https://systemlite.spot1.mx/api/consumptions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                company_id: user_company,
                msisdn: parametros.msisdn,
                date_init: parametros.dateNow,
                date_end: parametros.dateNow2
            })
        })

        if (response.status === 200) {
            const responseJson = await response.json();
            console.log(responseJson, " | RESPONSE JSON | ");
            setData(responseJson); // Actualiza el estado con los datos recibidos
        } else {
            // Alert.alert('Woops!', 'Al parecer no estás registrado, pide al administrador que te agregue.');
        }

    } catch (error) {
        console.log(error)
        //Alert.alert('Woops!', error);
    }
    }

    const customStyles = {
        rows: {
        style: {
            backgroundColor: '#343a40', // Color de fondo
            color: '#FFFFFF', // Color del texto
        },
        },
        headCells: {
        style: {
            backgroundColor: '#343a40', // Color de fondo de los encabezados
            color: '#FFFFFF', // Color del texto en los encabezados
            fontWeight: 'bold', // Texto en negrita para los encabezados
        },
        },
    };


    return(
    <>
    <div className="container">
        <SideBar logged={logged} setLogged={setLogged} user_name={user_name} setUsername={setUsername} user_lastname={user_lastname} setUserLastname={setUserLastname} user_company={user_company} setUserCompany={setUserCompany} user_email={user_email} setUserEmail={setUserEmail} />
        <div className="content col-12">
            <div className="col-10 d-block">
              
            <div className="col-12">
                <h3>Consumos de {msisdn} {dateNow} - {dateNow2}</h3>
                {subHeaderComponentMemo}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <label>Selecciona el rango de fechas: </label>
                <input 
                    type="date" 
                    value={dateNow} 
                    onChange={handleFechaCambio} 
                    className="form-control"
                />
                <input 
                    type="date" 
                    value={dateNow2} 
                    onChange={handleFechaCambio2} 
                    className="form-control"
                />
                    <button type="button" className="btn btn-sm btn-success" onClick={() => getConsumptios({msisdn, dateNow, dateNow2})}>Consultar</button>
                </div>
                <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    highlightOnHover
                    subHeader
                    persistTableHead
                    className="custom-table"
                    customStyles={customStyles}
                />
            </div>

            </div>

            

        </div>
    </div>
    </>
    )}