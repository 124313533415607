import { useState } from "react";
import "../styles/sidebar.css"
import logo from "../img/logo-principal.png"
import { NAVLINKS } from "./data";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../fontawesome';

export const SideBar = ({ logged, setLogged, user_name, setUsername, user_lastname, setUserLastname, user_company, setUserCompany, user_email, setUserEmail }) => {
  const navigate = useNavigate();
    const [isHover, setIsHover] = useState(false);

    const logout = () => {
      setLogged(false);
      navigate("/");
    }

    return (
        <aside className={`sidebar ${isHover ? "active" : ""}`}>
      <div className="open-btn" onClick={() => setIsHover((prev) => !prev)}>
        <span className="material-symbols-outlined"><FontAwesomeIcon icon="chevron-right" /></span>
      </div>
      <div className="wrapper">
        <div className="top__wrapper">
          <div className="header">
            <span className="header-logo">
              <img src={logo} alt="" />
            </span>
            <div className="header-details">
              <span className="header-name">{user_name} {user_lastname}</span>
              <span className="header-email">{user_email}</span>
            </div>
          </div>
          <div className="search-box">
            <span className="material-symbols-outlined"><FontAwesomeIcon icon="magnifying-glass" /></span>
            <input type="text" name="searchBox" placeholder="Search..." />
          </div>
          <nav className="sidebar-nav">
            <ul className="nav-menu">
              {NAVLINKS.map((item) => {
                return (
                  <li key={item.name} className="nav-menu__item">
                    <Link to={item.path} className="nav-menu__link">
                      <span><FontAwesomeIcon icon={item.icon} /></span>
                      <span className="text">{item.name}</span>
                    </Link>
                  </li>
                );
              })}
                <li key="logout" className="nav-menu__item">
                    <button type="button" className="nav-menu__link" onClick={logout}>
                        <span><FontAwesomeIcon icon="right-from-bracket" /></span>
                        <span className="text">Logout</span>
                    </button>
                </li>
            </ul>
          </nav>
        </div>
      </div>
    </aside>
    )
}
