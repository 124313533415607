import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { SideBar } from "../components/SideBar"
import "../styles/generic.css"
import 'chart.js/auto';
import DataTable from 'react-data-table-component';
import { Dropdown } from 'react-bootstrap';
// import Loader from "../components/Loader"



export const Lines = ({ logged, setLogged, user_name, setUsername, user_lastname, setUserLastname, user_company, setUserCompany, user_email, setUserEmail }) => {
  const navigate = useNavigate();
      const [data, setData] = useState([]);

      useEffect(() => {
        const verifyLogged = () => {
          console.log(user_company, " USER COMPANY")
          if(!logged){
            navigate('/');
          }
        }
    
        verifyLogged();
      }, [logged]);

      useEffect(() => {
        getLines();
    }, []);
      
      const columns = [
        {
          name: 'MSISDN',
          selector: row => row.MSISDN,
          sortable: true,
        },
        {
          name: 'ICC',
          selector: row => row.icc_id,
          sortable: true,
        },
        {
          name: 'TIPO',
          selector: row => row.producto,
          sortable: true,
        },
        {
          name: 'STATUS',
          selector: row => row.nStatus === "taken" ? "Usando" : "Sin Activar",
          sortable: true,
        },
        {
          name: 'Acciones', // Nueva columna para el botón
          cell: (row) => <ActionButton row={row} />,
          ignoreRowClick: true, // Evita que el botón dispare acciones de la tabla
          allowOverflow: true,
          button: true,
        }
      ];

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const filteredItems = data.filter(
      item => item.MSISDN && item.MSISDN.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
          if (filterText) {
              setResetPaginationToggle(!resetPaginationToggle);
              setFilterText('');
          }
      };

      return (
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <input className="form-control"
                  type="text"
                  placeholder="Search"
                  aria-label="Search Input"
                  value={filterText}
                  onChange={e => setFilterText(e.target.value)}
                  style={{ marginRight: '10px', padding: '5px' }}
              />
              <button onClick={handleClear}>Clear</button>
          </div>
      );
  }, [filterText, resetPaginationToggle]);

  const getLines = async () => {
      try {
          console.log("COMENZANDO CONSUMO DE API...");
          const response = await fetch('https://systemlite.spot1.mx/api/lines', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                "company_id": user_company
            })
          })

          if (response.status === 200) {
              const responseJson = await response.json();
              console.log(responseJson, " | RESPONSE JSON | ");
              setData(responseJson); // Actualiza el estado con los datos recibidos
          } else {
              // Alert.alert('Woops!', 'Al parecer no estás registrado, pide al administrador que te agregue.');
          }

      } catch (error) {
          console.log(error)
          //Alert.alert('Woops!', error);
      }
  }

  const customStyles = {
    rows: {
      style: {
        backgroundColor: '#343a40', // Color de fondo
        color: '#FFFFFF', // Color del texto
      },
    },
    headCells: {
      style: {
        backgroundColor: '#343a40', // Color de fondo de los encabezados
        color: '#FFFFFF', // Color del texto en los encabezados
        fontWeight: 'bold', // Texto en negrita para los encabezados
      },
    },
  };
  
    return (
      <>
        <div className="container">
          <SideBar logged={logged} setLogged={setLogged} user_name={user_name} setUsername={setUsername} user_lastname={user_lastname} setUserLastname={setUserLastname} user_company={user_company} setUserCompany={setUserCompany} user_email={user_email} setUserEmail={setUserEmail} />
          <div className="content col-12">
            <div className="col-12">
              <h2>Mis Líneas</h2>
              {subHeaderComponentMemo}
              <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                highlightOnHover
                subHeader
                persistTableHead
                className="custom-table"
                customStyles={customStyles}
              />
            </div>
          </div>
        </div>
      </>
    );
}

const ActionButton = ({ row }) => {
  const navigate = useNavigate();

  const activateUF = (row) => {
    console.log("Acción 1 seleccionada para", row);
    let icc = row.icc_id;
    icc = icc.slice(0, -1);
    navigate(`/activate-uf/${icc}`);
  };
  
  const consultaUF = (row) => {
    console.log("Acción 1 seleccionada para", row);
    navigate(`/consult-uf/${row.MSISDN}`);
  };

  const recargaUF = (row) => {
    console.log("Acción 1 seleccionada para", row);
    navigate(`/recharge-uf/${row.MSISDN}`);
  };
  
  const administrar = (row) => {
    navigate(`/barring-unbarring/${row.MSISDN}`);
  };
  
  const consumos = (row) => {
    navigate(`/consumptions/${row.MSISDN}`);
  };

  return (
    <Dropdown key={row.MSISDN}>
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        Acciones
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="1" href="#" onClick={() => activateUF(row)}>Activar</Dropdown.Item>
        <Dropdown.Item eventKey="2" href="#" onClick={() => recargaUF(row)}>Recargar</Dropdown.Item>
        <Dropdown.Item eventKey="3" href="#" onClick={() => consultaUF(row)}>Consultar</Dropdown.Item>
        <Dropdown.Item eventKey="4" href="#" onClick={() => administrar(row)}>Administrar</Dropdown.Item>
        <Dropdown.Item eventKey="5" href="#" onClick={() => consumos(row)}>Consumos</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

// Ejemplos de funciones para manejar las opciones del dropdown