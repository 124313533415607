import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { SideBar } from "../components/SideBar"
import "../styles/generic.css"
import 'chart.js/auto';
import DataTable from 'react-data-table-component';
import { Dropdown } from 'react-bootstrap';
// import Loader from "../components/Loader"



export const Movements = ({ logged, setLogged, user_name, setUsername, user_lastname, setUserLastname, user_company, setUserCompany, user_email, setUserEmail }) => {
  const navigate = useNavigate();
      const [data, setData] = useState([]);

      useEffect(() => {
        const verifyLogged = () => {
          console.log(user_company, " USER COMPANY")
          if(!logged){
            navigate('/');
          }
        }
    
        verifyLogged();
      }, [logged]);

      useEffect(() => {
        getLines();
    }, []);

    function formatDate(fecha) {
      const dateUtc = new Date(fecha);
      
      // Obtener los componentes individuales de la fecha
      const day = String(dateUtc.getDate()).padStart(2, '0'); // Día
      const month = String(dateUtc.getMonth() + 1).padStart(2, '0'); // Mes (se suma 1 porque getMonth devuelve 0-11)
      const year = String(dateUtc.getFullYear()).slice(-2); // Últimos dos dígitos del año
      const hours = String(dateUtc.getHours()).padStart(2, '0'); // Hora
      const minutes = String(dateUtc.getMinutes()).padStart(2, '0'); // Minutos
      const seconds = String(dateUtc.getSeconds()).padStart(2, '0'); // Segundos
    
      // Formatear la fecha en el formato deseado: DD-MM-YY HH:mm:ss
      const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
      
      return formattedDate;
    }
      
      const columns = [
        {
          name: 'MSISDN',
          selector: row => row.msisdn,
          sortable: true,
        },
        {
            name: 'Paquete',
            selector: row => row.rate_name,
            sortable: true,
          },
        {
          name: 'Oferta',
          selector: row => row.offerID,
          sortable: true,
        },
        {
          name: 'Order ID',
          selector: row => row.order_id,
          sortable: true,
        },
        {
          name: 'Movimiento',
          selector: row => row.movement,
          sortable: true,
        },
        {
          name: 'Fecha',
          selector: row => formatDate(row.effectiveDate),
          sortable: true,
        }
      ];

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const filteredItems = data.filter(
      item => item.msisdn && item.msisdn.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
          if (filterText) {
              setResetPaginationToggle(!resetPaginationToggle);
              setFilterText('');
          }
      };

      return (
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <input className="form-control"
                  type="text"
                  placeholder="Search"
                  aria-label="Search Input"
                  value={filterText}
                  onChange={e => setFilterText(e.target.value)}
                  style={{ marginRight: '10px', padding: '5px' }}
              />
              <button onClick={handleClear}>Clear</button>
          </div>
      );
  }, [filterText, resetPaginationToggle]);

  const getLines = async () => {
      try {
          console.log("COMENZANDO CONSUMO DE API...");
          const response = await fetch('https://systemlite.spot1.mx/api/movements', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                "company_id": user_company
            })
          })

          if (response.status === 200) {
              const responseJson = await response.json();
              console.log(responseJson, " | RESPONSE JSON | ");
              setData(responseJson); // Actualiza el estado con los datos recibidos
          } else {
              // Alert.alert('Woops!', 'Al parecer no estás registrado, pide al administrador que te agregue.');
          }

      } catch (error) {
          console.log(error)
          //Alert.alert('Woops!', error);
      }
  }

  const customStyles = {
    rows: {
      style: {
        backgroundColor: '#343a40', // Color de fondo
        color: '#FFFFFF', // Color del texto
      },
    },
    headCells: {
      style: {
        backgroundColor: '#343a40', // Color de fondo de los encabezados
        color: '#FFFFFF', // Color del texto en los encabezados
        fontWeight: 'bold', // Texto en negrita para los encabezados
      },
    },
  };
  
    return (
      <>
        <div className="container">
          <SideBar logged={logged} setLogged={setLogged} user_name={user_name} setUsername={setUsername} user_lastname={user_lastname} setUserLastname={setUserLastname} user_company={user_company} setUserCompany={setUserCompany} user_email={user_email} setUserEmail={setUserEmail} />
          <div className="content col-12">
            <div className="col-12">
              <h2>Mis Movimientos</h2>
              {subHeaderComponentMemo}
              <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                highlightOnHover
                subHeader
                persistTableHead
                className="custom-table"
                customStyles={customStyles}
              />
            </div>
          </div>
        </div>
      </>
    );
}

const ActionButton = ({ row }) => {
  const navigate = useNavigate();

  const activateUF = (row) => {
    console.log("Acción 1 seleccionada para", row);
    let icc = row.msisdn;
    navigate(`/activate-uf/${icc}`);
  };
  
  const consultaUF = (row) => {
    console.log("Acción 1 seleccionada para", row);
    navigate(`/consult-uf/${row.msisdn}`);
  };

  const recargaUF = (row) => {
    console.log("Acción 1 seleccionada para", row);
    navigate(`/recharge-uf/${row.msisdn}`);
  };
  
  const administrar = (row) => {
    navigate(`/barring-unbarring/${row.msisdn}`);
    console.log("Acción 2 seleccionada para", row);
  };
  
  const handleAction3 = (row) => {
    console.log("Acción 3 seleccionada para", row);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        Acciones
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#" onClick={() => activateUF(row)}>Activar</Dropdown.Item>
        <Dropdown.Item href="#" onClick={() => recargaUF(row)}>Recargar</Dropdown.Item>
        <Dropdown.Item href="#" onClick={() => consultaUF(row)}>Consultar</Dropdown.Item>
        <Dropdown.Item href="#" onClick={() => administrar(row)}>Administrar</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

// Ejemplos de funciones para manejar las opciones del dropdown