import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { SideBar } from "../components/SideBar"
import "../styles/generic.css"
import "../styles/buttons.css"
import "../styles/inputs.css"
import "../styles/cards.css"
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { getTokenAltan, barring, unbarring, deactivate, reactivate, deactivate4Ever } from '../helpers';
import 'chart.js/auto';
// import Loader from "../components/Loader"

export const SuspendReactivateTraffic = ({ logged, setLogged, user_name, setUsername, user_lastname, setUserLastname, user_company, setUserCompany, user_email, setUserEmail }) => {
  const { param1 } = useParams();
  const [accessToken, setAccessToken] = useState("empty");
  const [msisdn, setMsisdn] = useState("empty");
  const [ofertas, setOfertas] = useState([]);
  const [loader, setLoader] = useState(false);
  const [textLoader, setTextLoader] = useState("Cargando, espere...");
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const verifyLogged = () => {
      if(!logged){
        navigate('/');
      }
    }

    verifyLogged();
  }, [logged]);

  useEffect(() => {
    const fetchToken = async () => {
      setLoader(true);
      try {
        // Esperar a que la promesa se resuelva
        let tokenData = await getTokenAltan();
        
        // Verificar que el tokenData no tenga errores y actualizar el estado
        if (!tokenData.error) {
          setLoader(false);
          setIsDisabled(false);
          setAccessToken(tokenData.accessToken);
          console.log(tokenData, "TOKEN DATA");
          console.log(tokenData.accessToken, "TOKEN ACCESS TOKEN");
        } else {
          setLoader(false);
          console.error("Error:", tokenData.error_desc);
          Swal.fire({
            title: 'Uhhh :(',
            text: 'Error al obtener Token: '+tokenData.error_desc,
            icon: 'error'
          });
        }
      } catch (error) {
        setLoader(false);
        console.error("Error fetching token:", error);
        Swal.fire({
          title: 'Uhhh :(',
          text: 'Error al obtener Token: '+error,
          icon: 'error'
        });
      }
    };

    const showParam = () => {
      console.log(param1, " PARAMETRO");
      setMsisdn(param1);
    }

    fetchToken();
    showParam();
  }, []);

  const doBarring = async (data) => {
    setIsDisabled(true);
    setTextLoader("Aplicando Barring");
    setLoader(true);
    data.accessToken = accessToken;
    console.log(data, " DATA TO CONSULT UF")
    let response = await barring(data);
    
    if (!response.error) {
      console.log(response, " RESPONSE BARRING")
      Swal.fire({
        title: 'Hey!',
        text: 'Barring aplicado correctamente.',
        icon: 'success'
      });
    } else {
      console.error("Error:", response.error_desc);
      Swal.fire({
        title: 'Uhhh :(',
        text: 'Error al aplicar Barring: '+response.error_desc+' - '+response.description,
        icon: 'error'
      });
    }
    setLoader(false);
    setIsDisabled(false);
  }

  const doUnbarring = async (data) => {
    setIsDisabled(true);
    setTextLoader("Aplicando Unbarring");
    setLoader(true);
    data.accessToken = accessToken;
    console.log(data, " DATA TO CONSULT UF")
    let response = await unbarring(data);
    
    if (!response.error) {
      console.log(response, " RESPONSE UNBARRING")
      Swal.fire({
        title: 'Hey!',
        text: 'Unbarring aplicado correctamente.',
        icon: 'success'
      });
    } else {
      console.error("Error:", response.error_desc);
      Swal.fire({
        title: 'Uhhh :(',
        text: 'Error al aplicar Unbarring: '+response.error_desc+' - '+response.description,
        icon: 'error'
      });
    }
    setLoader(false);
    setIsDisabled(false);
  }

  const doDeactivate = async (data) => {
    setIsDisabled(true);
    setTextLoader("Aplicando Desactivación");
    setLoader(true);
    data.accessToken = accessToken;
    console.log(data, " DATA TO CONSULT UF")
    let response = await deactivate(data);
    
    if (!response.error) {
      console.log(response, " RESPONSE DEACTIVATE")
      Swal.fire({
        title: 'Hey!',
        text: 'Baja Temporal aplicada correctamente.',
        icon: 'success'
      });
    } else {
      console.error("Error:", response.error_desc);
      Swal.fire({
        title: 'Uhhh :(',
        text: 'Error al aplicar Baja Temporal: '+response.error_desc+' - '+response.description,
        icon: 'error'
      });
    }
    setLoader(false);
    setIsDisabled(false);
  }

  const doReactivate = async (data) => {
    setIsDisabled(true);
    setTextLoader("Aplicando Reactivación");
    setLoader(true);
    data.accessToken = accessToken;
    console.log(data, " DATA TO CONSULT UF")
    let response = await reactivate(data);
    
    if (!response.error) {
      console.log(response, " RESPONSE REACTIVATE")
      Swal.fire({
        title: 'Hey!',
        text: 'Reactivación aplicada correctamente.',
        icon: 'success'
      });
    } else {
      console.error("Error:", response.error_desc);
      Swal.fire({
        title: 'Uhhh :(',
        text: 'Error al Reactivar la MSISDN: '+response.error_desc+' - '+response.description,
        icon: 'error'
      });
    }
    setLoader(false);
    setIsDisabled(false);
  }

  const doDeactivate4Ever = async (data) => {
    setIsDisabled(true);
    setTextLoader("Aplicando Baja Definitiva");
    setLoader(true);
    data.accessToken = accessToken;
    console.log(data, " DATA TO CONSULT UF")
    let response = await deactivate4Ever(data);
    
    if (!response.error) {
      console.log(response, " RESPONSE doDeactivate4Ever")
      Swal.fire({
        title: 'Hey!',
        text: 'Baja Definitiva aplicada correctamente.',
        icon: 'success'
      });
    } else {
      console.error("Error:", response.error_desc);
      Swal.fire({
        title: 'Uhhh :(',
        text: 'Error al aplicar Baja Definitiva: '+response.error_desc+' - '+response.description,
        icon: 'error'
      });
    }
    setLoader(false);
    setIsDisabled(false);
  }

    return(
    <>
    <div className="container">
        <SideBar logged={logged} setLogged={setLogged} user_name={user_name} setUsername={setUsername} user_lastname={user_lastname} setUserLastname={setUserLastname} user_company={user_company} setUserCompany={setUserCompany} user_email={user_email} setUserEmail={setUserEmail} />
        <div className="content">
            <div className="col-12 d-block">
              <div className="col-12 d-flex justify-content-center">
                <div className="form-container col-8 ">
                  <h2>Barring/Unbarring de UF</h2>
                  <form>
                      <div className="form-group">
                          <label htmlFor="msisdn">MSISDN</label>
                          <input className="form-control" type="text" id="msisdn" name="msisdn" onChange={(e) => setMsisdn(e.target.value)} placeholder="5500000000" value={msisdn} required />
                      </div>
                      {loader && (
                        <div className="col-12">
                            <div className="loader-container">
                            <div className="loader"></div>
                            <div className='loader-text'>{textLoader}...</div>
                            </div>
                        </div>
                      )}
                      <div className="form-group">
                        <h4>Barring/Barring (Suspensión de Tráfico)</h4>
                      </div>
                      <div className="form-group">
                        <button disabled={isDisabled} type="button" className="btn mb-2 mr-2 btn-danger" onClick={() => doBarring({msisdn, accessToken})}>Suspender</button>
                        <button disabled={isDisabled} type="button" className="btn mb-2 mr-2 btn-success" onClick={() => doUnbarring({msisdn, accessToken})}>Reanudar</button>
                      </div>
                      <hr/>
                      <div className="form-group">
                        <h4>Desactivación/Reactivación</h4>
                      </div>
                      <div className="form-group">
                        <button disabled={isDisabled} type="button" className="btn mb-2 mr-2 btn-danger" onClick={() => doDeactivate({msisdn, accessToken})}>Desactivar</button>
                        <button disabled={isDisabled} type="button" className="btn mb-2 mr-2 btn-success" onClick={() => doReactivate({msisdn, accessToken})}>Reactivar</button>
                      </div>
                      <hr/>
                      <div className="form-group">
                        <h4 className="mb-0">Baja Definitiva</h4>
                        <small className="mt-0">Si realiza esta acción, no se podrá revertir, la SIM y el número quedarán inservibles.</small>
                      </div>
                      <div className="form-group">
                        <button disabled={isDisabled} type="button" className="btn mb-2 mr-2 btn-danger" onClick={() => doDeactivate4Ever({msisdn, accessToken})}>Dar de Baja</button>
                      </div>
                  </form>
                </div>
              </div>
            </div>
        </div>
    </div>
    </>
    )}