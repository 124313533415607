import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { SideBar } from "../components/SideBar"
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "../styles/generic.css"
import "../styles/buttons.css"
import "../styles/inputs.css"
import "../styles/cards.css"
import "../styles/cardResult.css"
import { getTokenAltan, consultUFAPI, getRatesActivation, getNumberByIcc, activateMSISDN } from '../helpers';
import 'chart.js/auto';
// import Loader from "../components/Loader"

export const ActivateUF = ({ logged, setLogged, user_name, setUsername, user_lastname, setUserLastname, user_company, setUserCompany, user_email, setUserEmail }) => {
  const { param1 } = useParams();
  const [accessToken, setAccessToken] = useState("empty");
  const [dataActivation, setDataActivation] = useState({});
  const [msisdn, setMsisdn] = useState("empty");
  const [selectedOfferID, setSelectedOfferID] = useState("");
  const [ICC, setICC] = useState("");
  const [ofertas, setOfertas] = useState([]);
  const [IDS, setIDS] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showTicket, setShowTicket] = useState(false);
  const [loader, setLoader] = useState(false);
  const [textLoader, setTextLoader] = useState("Cargando, espere...");
  const navigate = useNavigate();
  useEffect(() => {
    const verifyLogged = () => {
      if(!logged){
        navigate('/');
      }
    }

    verifyLogged();
  }, [logged]);

  useEffect(() => {
    const getRates = async (data) => {
        try {
          let dataResp = await getRatesActivation(data);
          
          // Verificar que el tokenData no tenga errores y actualizar el estado
          if (!dataResp.error) {
            setOfertas(dataResp.rates);
              let resultArray = dataResp.rates.reduce((acc, rate) => {
                acc[rate.offerID] = rate.id;
                return acc;
            }, {});
            setIDS(resultArray);

            if (dataResp.rates.length > 0) {
              setSelectedOfferID(dataResp.rates[0].offerID); // Seleccionar el primer offerID por defecto
            }
            console.log(dataResp, " DATA RESP GET RATES");
          } else {
            // console.error("Error: ", dataResp.error_desc);
            Swal.fire({
              title: 'Uhhh :(',
              text: 'Error al obtener Ofertas Disponibles: '+dataResp.error_desc,
              icon: 'error'
            });
          }
        } catch (error) {
        //   console.error("Error fetching token: ", error);
        Swal.fire({
          title: 'Uhhh :(',
          text: 'Error al obtener Ofertas Disponibles: '+error,
          icon: 'error'
        });
        }
      };

    const getNumber = async (data) => {
      setLoader(true);
        try {
          let dataResp = await getNumberByIcc(data);
          
          // Verificar que el tokenData no tenga errores y actualizar el estado
          if (!dataResp.error) {
            // console.log(dataResp, " DATA RESP");
            const { number } = dataResp;
            const { MSISDN, producto, id, imsi } = number;
            setTextLoader("Obteniendo MSISDN");
            setMsisdn(MSISDN);
            setTextLoader("Obteniendo planes disponibles");
            getRates({company_id: user_company});
            let tokenData = await getTokenAltan();
            if (!tokenData.error) {
                setAccessToken(tokenData.accessToken);
                // console.log(tokenData.accessToken, " TOKEN")
                doConsult({msisdn: MSISDN, accessToken: tokenData.accessToken});
            } else {
                // console.error("Error:", tokenData.error_desc);
                setLoader(false);
                setIsDisabled(true);
                Swal.fire({
                  title: 'Uhhh :(',
                  text: 'Error con operación TK: '+tokenData.error_desc,
                  icon: 'error'
                });
            }

            
          } else {
            setLoader(false);
            setIsDisabled(true);
            // console.error("Error: ", dataResp.error_desc);
            Swal.fire({
              title: 'Uhhh :(',
              text: 'Error al buscar el MSISDN: '+dataResp.error_desc,
              icon: 'error'
            });
          }
        } catch (error) {
          setLoader(false);
          setIsDisabled(true);
        //   console.error("Error fetching token: ", error);
        Swal.fire({
          title: 'Uhhh :(',
          text: 'Error al obtener MSISDN: '+error,
          icon: 'error'
        });
        }
      };

    const init = () => {
    //   console.log(param1, " PARAMETRO");
      setICC(param1);
      getNumber({icc: param1});
    }

    init();
  }, []);

  const doConsult = async (data) => {
    setTextLoader("Validando ICC");
    let response = await consultUFAPI(data);
    
    if (!response.error) {
      if(response.responseSubscriber.status.subStatus === "Idle"){
        setLoader(false);
          console.log("EXCELENTE, SE PUEDE ACTIVAR!!!");
          setIsDisabled(false);
        }else{
          setLoader(false);
          console.log("NO SE PUEDE ACTIVAR!!! :(");
          Swal.fire({
            title: 'Uhhh :(',
            text: 'Esta MSISDN no se puede activar, ya que ha sido activada anteriormente. Intenta con otra MSISDN. :)',
            icon: 'error'
          });
          setIsDisabled(true);
      }
    } else {
      setLoader(false);
    //   console.error("Error:", response.error_desc);
    Swal.fire({
      title: 'Uhhh :(',
      text: 'Error al obtener MSISDN: '+response.error_desc,
      icon: 'error'
    });
    }
  }

  const activateNumber = async (data) => {
      console.log(data, " activate number");
      data.rate_id = IDS[data.offerID];
    let response = await activateMSISDN(data);
    
    if (!response.error) {
        setShowTicket(true);
        console.log(response, " RESPONSE DATA ACTIVATION");
        setDataActivation(response);
    } else {
      Swal.fire({
        title: 'Uhhh :(',
        text: 'Error al activar: '+response.error_desc,
        icon: 'error'
      });
      console.error("Error:", response.error_desc, " ERROR EN ACTIVACION");
    }
  }

    return(
    <>
    <div className="container">
        <SideBar logged={logged} setLogged={setLogged} user_name={user_name} setUsername={setUsername} user_lastname={user_lastname} setUserLastname={setUserLastname} user_company={user_company} setUserCompany={setUserCompany} user_email={user_email} setUserEmail={setUserEmail}/>
        <div className="content">
            <div className="col-10 d-block">

            <div className={`col-12 ${showTicket ? 'd-flex justify-content-center' : 'd-none'} mt-3 mb-3`}>
                <div className="col-6">
                    <div className="cardTicket">
                        <div className="iconTicket">
                        </div>
                        <h1 className="titleTicket">
                            ¡¡Activación exitosa!!
                        </h1>
                        <p className="subtitleTicket">
                            Analiza los detalles de la activación, si encuentras algo raro o erróneo, comunícate con Soporte Técnico
                        </p>
                    </div>

                    {!showTicket ? '' : 
                        <div className="cardTicket">
                            <ul className="ulTicket">
                                <li className="liTicket">
                                    <span className="spanTicket">Fecha y Hora</span>
                                    <span className="spanTicket" >{dataActivation.effectiveDate}</span>
                                </li>
                                <li className="liTicket">
                                    <span className="spanTicket">Número</span>
                                    <span className="spanTicket" >{dataActivation.msisdn}</span>
                                </li>
                                <li className="liTicket">
                                    <span className="spanTicket">Offering ID</span>
                                    <span className="spanTicket" >{dataActivation.offeringId}</span>
                                </li>
                                <li className="liTicket">
                                    <span className="spanTicket">Order ID</span>
                                    <span className="spanTicket" >{dataActivation.order.id}</span>
                                </li>
                            </ul>
                        </div>
                    }
                    <div className="cardTicket">
                        <div className="cta-row">
                            <a href="" className="successBtn">
                                Hacer otra Activación
                            </a>
                        </div>
                    </div>
                </div>
              </div>

            {showTicket ? '' : 
            
                <div className="col-10 d-flex justify-content-center">
                    <div className="form-container col-6 ">
                        <h2>Activación de UF</h2>
                        <form>
                        <div className="form-group">
                            <label htmlFor="icc">ICC</label>
                            <input className="form-control" type="text" id="icc" name="icc" onChange={(e) => setICC(e.target.value)} placeholder="5500000000" value={ICC} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="ofertas">Ofertas Disponibles</label>
                            <select className="form-control" id="ofertas" name="ofertas" onChange={(e) => setSelectedOfferID(e.target.value)}>
                                {/* Iteramos sobre las ofertas para generar las opciones del select */}
                                {ofertas.map((oferta) => (
                                <option key={oferta.id} value={oferta.offerID}>
                                    {oferta.rate_name}
                                </option>
                                ))}
                            </select>
                        </div>

                        {loader && (
                          <div className="col-12">
                            <div className="loader-container">
                              <div className="loader"></div>
                              <div className='loader-text'>{textLoader}...</div>
                            </div>
                          </div>
                        )}

                        <div className="form-group">
                            <button type="button" disabled={isDisabled} className="btn mb-2 btn-success" onClick={() => activateNumber({msisdn, accessToken, offerID: selectedOfferID})}>Activar</button>
                        </div>
                        </form>
                    </div>
                </div>
            }
              
            </div>
        </div>
    </div>
    </>
    )}