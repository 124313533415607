export const NAVLINKS = [
    {
        name: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        name: 'Líneas',
        path: '/lines',
        icon: 'phone'
    },
    {
        name: 'Movimientos',
        path: '/movements',
        icon: 'list-check'
    }
]